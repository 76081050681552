.main-table {
  width: 1000px;
}

td {
  border: 0px solid black;
  text-align: center;
}

.vid-cell {
  width: 640px;
  vertical-align: top;
}

.current-cell {
  height: 300px;
  padding-bottom: 0em;
  padding-top: 0em;
}

.lineup-cell {
  height: auto;
  padding-top: 0px;
  padding-bottom: 0px;
  vertical-align: top;
}

.drinking-player {
  font-size: 3.5em;
  line-height: 1em;
  font-weight: bold;
  margin-top: 0em;
  margin-bottom: 0.2em;
}

.on-deck-player {
  font-size: 1.5em;
  margin-top: 0em;
  margin-bottom: 0em;

}

.lineup-header {
  margin-top: 1em;
  text-decoration: underline;
  margin-bottom: 0em;
}

.lineup-elt {
  margin-top: 0em;
  margin-bottom: 0em;
}

#back {
  margin-top: 1em;
  font-size: 1.2em
}